import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import SVG from 'react-inlinesvg'
import gatsbySVG from '../../../../flow-ui/flow-ui-components/assets/error-404.svg'


const Page404 = props => (
  <Layout {...props}>
    <Seo title='Página no encontrada' />
    <Divider />
    <Stack>
      <Main>
        <Section>
        <SVG src={gatsbySVG} style={{ width: `300px` }} />
          <PageTitle
            header="Ups... Lo lamento, parece que lo que intentas buscar no existe"
            subheader='Es posible que haya escrito mal la dirección o que la página se haya movido.'
          />
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            Volver al inicio
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Page404
